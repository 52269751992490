import * as React from "react"
import Layout from "../layout"

import '../styles/documents.scss';

const AvisoDePrivacidad = () => {
    const business = "Consorcio AAA Elite S de RL de CV";
    const email = "jlgomez@creditaria.com";
    const web = "www.mimejorhipoteca.com"
    const addresses = [
        "Av. Vicente Guerrero # 259 Col. Independencia, entre Av. Cortez y Av. Águilas, Ensenada Baja California, México."
    ];
    const phone = "1301654";

    return (
        <Layout>
            <main className="wrapper">
                <h1>Aviso de privacidad</h1>
                <h2>Responsable de la protección de datos personales.</h2>
                <p>En cumplimiento a lo dispuesto por la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, publicada en el Diario Oficial de la Federación el día 5 de Julio de 2010, <b>{business}</b>. hace del conocimiento del público en general lo siguiente:</p>
                
                <h2>La Identidad y Domicilio del Responsable</h2>
                <p><b>{business}</b></p>
                { 
                    addresses.map(address => {
                        return(
                            <p>{address}</p>
                        )
                    })
                }
                {/* <p>Pedro Ramírez Vázquez, Piso 10, Parque Corporativo u-calli San Pedro Garza García, Nuevo León 64750</p>
                <p>Insurgentes Sur 1809, Piso 7. CP. 01020 Guadalupe Inn, Alvaro Obregón Ciudad de México, México.</p> */}
                <p>Tel. <a href={`tel:646${phone}`}>(646){phone}</a></p>

                <h2>Las Finalidades Del Tratamiento De Datos</h2>
                <p>Los datos personales que se encuentran en las bases de datos de <b>{business}</b>. y de sus subsidiarias y filiales son utilizados para la prestación de los servicios de asesoría financiera y de inversión, así como de promoción, comercialización y publicidad de créditos y préstamos de cualquier naturaleza ofrecidos por terceras personas y la tramitación y gestión de los mismos ante dichas terceras personas. La mediación, gestión o asesoría a personas morales o físicas para la obtención de los créditos ante entidades financieras o la comisión o representación o prestación de servicios especializados en el ramo hipotecario y financiero; así como también la prestación de otros servicios relacionados con el negocio hipotecario o inmobiliario y demás actividades necesarias. Contratar, reclutar, seleccionar y capacitar personal con la finalidad de proporcionar servicios laborales en las áreas administrativas, informáticas y de servicios de apoyo a personas físicas y morales. Para llevar a cabo nuestra labor se utilizan datos personales de carácter general y datos patrimoniales.</p>

                <h2>Opciones y Medidas para Limitar el Uso o Divulgación de los Datos</h2>
                <p>Para restringir, limitar o controlar tus datos personales, <b>{business}</b> cuenta siempre con medidas de Seguridad para evitar su divulgación, implementando diversos controles de seguridad y protección de la información en sus servidores, equipos de cómputo y redes informáticas. De igual manera en la utilización de información de forma física se efectúa un estricto control de vigilancia por parte del personal, quien además se adhiere desde el momento de su ingreso a las cláusulas de confidencialidad de la empresa.</p>
                <p>Puedes Acceder, Rectificar, Cancelar tus datos personales, así como Oponerte (ejercicio de tus derechos ARCO) al tratamiento de los mismos o en su caso revocar el consentimiento para el fin que nos los hayas otorgado a través de tu solicitud.</p>
                <p>Las medidas para ejercer los derechos de acceso, rectificación, cancelación u oposición de conformidad a lo dispuesto en esta Ley y Procedimiento por el cual el responsable comunicara a los titulares de cambios al aviso de privacidad de conformidad con lo previsto en esta Ley.</p>
                <p>El titular podrá ejercer estos derechos, por si mismos o a través de su representante legal por medio de una solicitud dirigida a <b>{business}</b> que contenga como mínimo los siguientes datos:</p>
                <ul>
                    <li>Nombre del titular.</li>
                    <li>Domicilio para recibir respuesta a la solicitud.</li>
                    <li>Documentos con que acredite su identidad el titular o la representación legal.</li>
                    <li>Descripción clara y precisa de los datos personales sobre los que ejercerá sus derechos.</li>
                    <li>Cualquier elemento que facilite la localización de datos personales.</li>
                </ul>
                <p>Dicha solicitud y documentos podrán presentarse físicamente en el domicilio de <b>{business}</b> o por medio de documentos digitalizados a la cuenta de email: <a href={`mailto:${email}`}>{email}</a> a la que recaerá respuesta en un plazo máximo de 20 días hábiles contados desde la fecha de recepción de la solicitud y cubriendo previamente los gastos de envío que se pudieran generar por el envío de la información.</p>
            
                <h2>Transferencia de Datos que se Efectúen</h2>
                <p><b>{business}</b> evitará siempre la transferencia de datos. Solo en caso de ser necesario por así requerirlo las actividades que correspondan al giro de la empresa y por mandato judicial se efectuará la transferencia de los datos personales con el cuidado, medidas y controles necesarios para el manejo de la información de tipo confidencial de la empresa.</p>
                <p>En el caso de que usted haya sido referido por un tercero mediante una liga (URL) para acceder a nuestro precalificador, usted autoriza a <b>{business}</b> a compartir con la persona propietaria de dicha liga (URL) un reporte ejecutivo del resultado de su precalificación, que incluye los siguientes conceptos: su nombre y apellidos, la estimación de su línea máxima del crédito y del valor de la propiedad, así como nuestra opinión respecto de su viabilidad para el crédito para el que se precalifica.</p>

                <h2>Procedimiento y Medio por el cual se Comunicará a los Titulares de Cambios al Aviso de Privacidad.</h2>
                <p><b>{business}</b> se reserva el derecho de realizar modificaciones al Aviso de Privacidad. Para dar a conocer los cambios en el Aviso de Privacidad a los titulares se comunicará a través de la página web {web} y en todo caso se aplicarán las medidas compensatorias que refiere la ley.</p>
            </main>
        </Layout>
    )
}

export default AvisoDePrivacidad
